<template>
  <div class="main">
    <div v-if="!detailShow">
      <div class="top">
        <el-tabs v-model="activeName">
          <el-tab-pane label="最新话题" name="1"></el-tab-pane>
          <el-tab-pane label="热门话题" name="2"></el-tab-pane>
          <el-tab-pane label="全部话题" name="3"></el-tab-pane>
          <el-tab-pane label="已屏蔽话题" name="4"></el-tab-pane>
        </el-tabs>
        <el-form :inline="true" :model="queryForm" class="demo-form-inline">
          <el-form-item label="发布时间">
            <el-date-picker
              v-model="queryForm.time"
              size="small"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              style="width: 340px"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model="queryForm.key"
              size="small"
              style="width: 200px"
              placeholder="请输入话题关键词"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="small" @click="onSubmit">查询</el-button>
            <el-button type="primary" size="small" @click="reset">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div v-loading="loading" class="list">
        <div v-if="tableData.length == 0" class="noData">暂无数据</div>
        <div v-for="(item, i) in tableData" :key="i" class="item">
          <div class="left" @click="detail(item)">
            <el-image
              style="width: 80px; height: 80px"
              :src="!!item.avatarUrl ? item.avatarUrl : require('@/assets/img/default.png')"
              fit="cover"
            ></el-image>
            <div class="text">
              <div class="title">{{ item.subject }}</div>
              <div class="desc">
                <span>{{ item.userName }}</span>
                <span class="time"><i class="el-icon-time"></i> {{ item.createTime }}</span>
                <span>（<i class="el-icon-picture-outline"></i> {{ item.picNum }}张配图）</span>
              </div>
            </div>
          </div>
          <div class="right">
            <span class="flexCenter">
              <img width="16" src="../../assets/xin.png" alt="" />&ensp;喜欢 &ensp;{{ item.goodsNum }}</span
            >
            <span style="margin: 0 10px"><i class="el-icon-chat-round"></i> 评论&ensp;{{ item.commentNum }}</span>
            <el-link v-if="powerList.find((e) => e.nodeCode == 'YY_MARKET_DELTOPIC')" type="danger" @click="del(item)"
              >删除</el-link
            >
          </div>
        </div>
        <div class="total">总贴数量：{{ total }}</div>
      </div>
      <Pages
        :total="total"
        :current-page="form.pageNumber"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
      />
    </div>
    <Detail v-else :topic-id="topicId" @back="back" />
  </div>
</template>

<script>
import moment from 'moment'
import Pages from '@/components/pages'
import Detail from './detail.vue'
import { shootTopicGetList, shootTopicDelete } from '@/api'
export default {
  components: {
    Pages,
    Detail,
  },
  data() {
    return {
      activeName: '1',
      form: { data: {}, pageNumber: 1, pageSize: 10 },
      queryForm: {},
      total: 0,
      detailShow: false,
      tableData: [],
      loading: false,
      topicId: '',
    }
  },
  computed: {
    powerList: function () {
      return this.$store.getters.getPowerList
    },
  },
  watch: {
    activeName: function (val) {
      this.getTableData()
    },
  },
  mounted() {
    this.getTableData()
  },
  methods: {
    back(e) {
      this.detailShow = false
      if (e) {
        this.form.pageNumber = 1
        this.getTableData()
      }
    },
    getTableData() {
      this.loading = true
      const data = { ...this.queryForm }
      if (data.time) {
        data.sendTimeStart = moment(data.time[0]).format('YYYY-MM-DD HH:mm:ss')
        data.sendTimeEnd = moment(data.time[1]).format('YYYY-MM-DD HH:mm:ss')
      }
      delete data.time
      this.form.data = { ...data }
      this.form.data.type = this.activeName
      shootTopicGetList(this.form).then((res) => {
        this.loading = false
        this.tableData = res.data.content
        this.total = Number(res.data.total)
      })
    },
    del(row) {
      this.$confirm('确认删除该条数据?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          shootTopicDelete({
            data: {
              topicId: row.topicId,
            },
          }).then((res) => {
            this.$message({
              type: 'success',
              message: '删除成功!',
            })
            this.form.pageNumber = 1
            this.getTableData()
          })
        })
        .catch(() => {})
    },
    detail(row) {
      this.topicId = row.topicId
      this.detailShow = true
    },
    handleSizeChange(val) {
      this.form.pageSize = val
      this.form.pageNumber = 1
      this.getTableData()
    },
    handleCurrentChange(val) {
      this.form.pageNumber = val
      this.getTableData()
    },
    onSubmit() {
      this.form.pageNumber = 1
      this.getTableData()
    },
    reset() {
      this.queryForm = {}
      this.form.pageNumber = 1
      this.getTableData()
    },
  },
}
</script>

<style lang="less" scoped>
.main {
  background-color: #fff;
  padding: 16px;
  .demo-form-inline {
    margin-left: 40px;
  }
  .title {
    line-height: 50px;
    font-weight: bold;
  }
  .top {
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ddd;
  }
  .list {
    padding: 0 20px;
    min-height: 200px;
    position: relative;
    .total {
      position: absolute;
      bottom: -50px;
      color: #666;
      font-size: 14px;
    }
    .noData {
      text-align: center;
      color: #999;
      font-size: 14px;
      line-height: 100px;
    }
    .el-image {
      border-radius: 50px;
    }
    .item {
      .el-link {
        width: 30px;
        display: inline-block;
      }
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px;
      border-bottom: 1px #ddd solid;
      .left {
        cursor: pointer;
        display: flex;
        flex: 1;
        .text {
          padding-left: 20px;
          padding-right: 80px;
          flex: 1;
          width: 0;

          .title {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .desc {
            color: #999;
            .time {
              padding: 0 10px;
            }
          }
        }
      }
      .right {
        color: #999;
        display: flex;
        font-size: 14px;
      }
      .right > span {
        min-width: 100px;
      }
    }
  }
  ::v-deep .el-tabs {
    .el-tabs__header {
      margin: 0;
    }
    .el-tabs__nav {
      height: 63px;
    }
    .el-tabs__nav-wrap::after {
      height: 0px;
    }
  }
}
</style>
