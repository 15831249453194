<template>
  <div>
    <div v-loading="detailLoading" class="item top">
      <div class="left">
        <el-image
          style="width: 80px; height: 80px"
          :src="!!data.avatarUrl ? data.avatarUrl : require('@/assets/img/default.png')"
          fit="cover"
        ></el-image>
        <div class="text">
          <div class="title">{{ data.subject }}</div>
          <div class="desc">
            <span>{{ data.userName }}</span>
            <span>（用户ID:{{ data.userId }}）</span>
            <span class="time">{{ data.createTime }}</span>
            <span class="flexCenter">
              <img width="16" src="../../assets/xin.png" alt="" /> 喜欢 ({{ data.goodsNum }})</span
            >
            <span style="margin: 0 24px"
              ><i class="el-icon-chat-round"></i> 评论({{ data.commentNum }})</span
            >
          </div>
        </div>
      </div>
      <div class="right">
        <el-button
          v-if="powerList.find((e) => e.nodeCode == 'YY_MARKET_DELTOPIC')"
          type="danger"
          size="small"
          @click="delTalk"
          >删除</el-button
        >
        <el-button size="small" @click="back">返回</el-button>
      </div>
    </div>
    <div class="imgs">
      <el-image
        v-for="(img, i) in data.pictureList"
        :key="i"
        style="width: 150px; height: 150px"
        :src="img"
        fit="cover"
        :preview-src-list="data.pictureList"
      ></el-image>
    </div>
    <div class="remark">
      <div class="all">
        <span>
          <span class="line"></span>
          <span style="font-weight: bold">全部评论</span> &emsp;{{ total }}条
        </span>
        <el-checkbox v-model="isShield" :true-label="1" :false-label="0" @change="hanldChange"
          >{{ isShieldTotal }}条已屏蔽评论</el-checkbox
        >
      </div>
      <div v-if="tableData.length == 0" class="noData">暂无数据</div>
      <div v-loading="loading">
        <div v-for="(item, i) in tableData" :key="i" class="item">
          <div class="left">
            <el-image
              style="width: 60px; height: 60px"
              :src="!!item.avatarUrl ? item.avatarUrl : require('@/assets/img/default.png')"
              fit="cover"
            ></el-image>
            <div class="text">
              <div class="title">
                <span> {{ item.userName }} </span>
                <span class="desc">（用户ID:{{ item.userId }}）</span>
                <span class="desc">{{ item.createTime }}</span>
              </div>
              <div class="desc subject">{{ item.subject }}</div>
            </div>
          </div>
          <div class="right">
            <el-link
              v-if="powerList.find((e) => e.nodeCode == 'YY_MARKET_DELCOMMENT')"
              type="danger"
              @click="del(item)"
              >删除</el-link
            >
          </div>
        </div>
      </div>
    </div>
    <Pages
      :current-page="form.pageNumber"
      :total="total"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    />
  </div>
</template>

<script>
import Pages from '@/components/pages'

import {
  shootTopicDetail,
  shootTopicCommentGetList,
  shootTopicCommentDelete,
  shootTopicDelete,
} from '@/api'

export default {
  components: {
    Pages,
  },
  props: {
    topicId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      total: 0,
      form: { data: {}, pageNumber: 1, pageSize: 10 },
      data: {},
      tableData: [],
      detailLoading: false,
      loading: false,
      isShield: 0,
      isShieldTotal: 0,
    }
  },
  computed: {
    powerList: function () {
      return this.$store.getters.getPowerList
    },
  },
  async mounted() {
    this.shootTopicDetail()
    await shootTopicCommentGetList({
      data: {
        topicId: this.topicId,
        isShield: 1,
      },
      pageNumber: 1,
      pageSize: 1,
    }).then((res) => {
      this.isShieldTotal = Number(res.data.total)
    })
    await this.getTableData()
  },
  methods: {
    hanldChange() {
      this.form.pageNumber = 1
      this.getTableData()
    },
    shootTopicDetail() {
      this.detailLoading = true
      shootTopicDetail({
        data: {
          topicId: this.topicId,
        },
      }).then((res) => {
        this.detailLoading = false
        this.data = res.data
        this.data.pictureList = this.data.pictureList ? this.data.pictureList : []
      })
    },
    async getTableData() {
      this.loading = true
      this.form.data.topicId = this.topicId
      this.form.data.isShield = this.isShield === 0 ? undefined : this.isShield
      await shootTopicCommentGetList(this.form).then((res) => {
        this.loading = false
        this.tableData = res.data.content
        this.total = Number(res.data.total)
      })
    },
    delTalk() {
      this.$confirm('确认删除该条数据?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          shootTopicDelete({
            data: {
              topicId: this.data.topicId,
            },
          }).then((res) => {
            this.$message({
              type: 'success',
              message: '删除成功!',
            })
            this.$emit('back', 1)
          })
        })
        .catch(() => {})
    },
    del(row) {
      this.$confirm('确认删除该条数据?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          shootTopicCommentDelete({
            data: {
              commentId: row.commentId,
            },
          }).then((res) => {
            this.$message({
              type: 'success',
              message: '删除成功!',
            })
            this.form.pageNumber = 1
            this.getTableData()
          })
        })
        .catch(() => {})
    },
    handleSizeChange(val) {
      this.form.pageSize = val
      this.form.pageNumber = 1
      this.getTableData()
    },
    handleCurrentChange(val) {
      this.form.pageNumber = val
      this.getTableData()
    },
    back() {
      this.$emit('back')
    },
  },
}
</script>

<style lang="less" scoped>
.noData {
  text-align: center;
  line-height: 100px;
  font-size: 14px;
  color: #999;
}

.item {
  background-color: #f9f9f9;
  .title {
    line-height: 50px;
    font-weight: bold;
  }

  .el-image {
    border-radius: 50px;
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  .left {
    display: flex;
    flex: 1;
    .text {
      flex: 1;
      width: 0;
      padding-left: 20px;
      .title,
      .subject {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding-right: 100px;
      }

      .desc {
        color: #999;
        display: flex;
        align-items: center;
        font-size: 14px;
        .time {
          padding: 0 10px;
        }
      }
    }
  }
  .right {
    color: #999;
    display: flex;
    font-size: 14px;
  }
}
.imgs {
  padding: 10px;
  .el-image {
    margin: 8px 10px;
  }
}
.remark {
  .all {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 20px;
  }
  .line {
    background-color: #0079fe;
    display: inline-block;
    width: 4px;
    height: 20px;
    margin-right: 10px;
  }
  .item {
    background-color: #fff;
    border-bottom: 1px solid #ddd;
    .title {
      display: flex;
      line-height: 40px;
      font-size: 16px;
      .desc {
        color: #999;
        font-weight: normal;
        font-size: 14px;
      }
    }
  }
}
</style>
